import React from 'react';
import { GiGraduateCap, GiStarsStack } from 'react-icons/gi';
import { ImLocation2 } from 'react-icons/im';
import { AiTwotonePhone } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import { BsCardHeading, BsBuilding, BsDot } from 'react-icons/bs';
import { CgBriefcase } from 'react-icons/cg';
import { FaRegAddressCard } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoRocketOutline } from 'react-icons/io5';

import ProfileImg from '../images/sushil-profile-sqre-no-bg.png';
import '../css/resume.scss';
import '@fontsource/roboto-condensed/700.css';

export default function Top() {
  return (
    <div className="flex justify-center w-full cv-container">
      <div className="w-full flex bg-white gap-8">
        <LeftSection />
        <RightSection />
      </div>
    </div>
  );
}

function RightSection() {
  return (
    <div className="w-3/4 py-12 pl-4 pr-20 flex flex-col gap-8 right-section">
      <div className="flex flex-col gap-4 right-header">
        <h1 className="text-5xl">Sushil Chaturvedi</h1>
        <div className="text-2xl">Founder, workstudio.app</div>
      </div>
      <div className="flex flex-col gap-8 right-body border-l ml-3">
        <SummarySection />
        <WorkStudioExpSection />
        <ConsultingExpSection />
        <EmploymentSection />
      </div>
    </div>
  );
}

function SummarySection() {
  return (
    <div className="summary flex flex-col gap-6">
      <div className="text-2xl pl-10 relative">
        <div className="absolute -left-3 -top-3 py-4 bg-white">
          <BsCardHeading />
        </div>
        <h2>Professional Summary</h2>
      </div>
      <ul className="pl-10">
        <li>13+ years of experience in leading web application development</li>
        <li>Well recognized for technical leadership and acumen in fast-paced environments</li>
        <li>Strong experience in data modeling and system architecture</li>
        <li>Strong knowhow across all facets of software businesses</li>
        {/* <li>Detail oriented with a strong focus on quality</li> */}
      </ul>
    </div>
  );
}

function WorkStudioExpSection() {
  return (
    <div className="experience flex flex-col gap-4">
      <div className="flex justify-between items-end">
        <div className="text-2xl pl-10 relative">
          <div className="absolute -left-3 -top-3 py-4 bg-white">
            <IoRocketOutline />
          </div>
          <h2>Sole Founder | WorkStudio, workstudio.app</h2>
        </div>
        <div className="time-period text-base">May 2020 - Present</div>
      </div>
      <div className="flex flex-col gap-8 pl-10">
        <div className="flex flex-col gap-2">
          <div>
            The idea behind WorkStudio was to create a{' '}
            <b>work management solution for fast paced product teams</b> that is highly effective
            while maintaining a very low footprint. Key focus has been to build a tool that can
            drive team productivity as well as personal productivity at the workplace, boosted by a
            delightful experience for both developers and managers.
            <br />
            <br />
            Role: As the solo member, owned the execution of all areas - Product, Engineering, and
            Marketing; gained immense learning and perspective as a result.
            <br />
            <br />
            <ul>
              <li>Developed multiple prototypes in the quest for an optimal solution</li>
              <li>
                Interteracted with potential clients to understand their needs and pain points.
              </li>
              <li>
                Developed website content and interfaced with designers to create a world class
                design.
              </li>
            </ul>
            <br />
            Tech Stack: React, TailwindCSS, NodeJS, Neo4j, Firebase functions
          </div>
        </div>
      </div>
    </div>
  );
}

function EmploymentSection() {
  return (
    <div className="experience flex flex-col gap-4">
      <div className="text-2xl pl-10 relative">
        <div className="absolute -left-3 -top-4 py-4 bg-white">
          <BsBuilding />
        </div>
        <h2>Employment History</h2>
      </div>
      <div className="flex flex-col gap-8 pl-10">
        <HiverExp />
        <PayuExp />
        <MobiculesExp />
      </div>
    </div>
  );
}

function ConsultingExpSection() {
  return (
    <div className="experience flex flex-col gap-4">
      <div className="flex justify-between items-end">
        <div className="text-2xl pl-10 relative">
          <div className="absolute -left-3 -top-3 py-4 bg-white">
            <CgBriefcase />
          </div>
          <h2>Consulting Experience</h2>
        </div>
        <div className="time-period text-base">Sep 2016 - April 2020</div>
      </div>
      <div className="flex flex-col gap-8 pl-10">
        <ConsultantExp />
      </div>
    </div>
  );
}

function ConsultantExp() {
  return (
    <div className="flex flex-col gap-6">
      {/* <div>
          <h3 className="my-4">WorkStudio, workstudio.app</h3>
          <div className="mb-2">
            Conceptualized and created a work management solution to enable everyone in the
            organization manage their work and time better.
          </div>
        </div> */}
      <Experience
        title="Technology Consultant | Hiver, hiverhq.com"
        role={
          <div>
            Role: Design new systems for upcoming features; Upgrade existing system architecture to
            meet high availability requirements; Assist team in day-to-day technical decisions.
          </div>
        }
      >
        <li>Designed Hiver's analytics service with serverless components</li>
        <li>
          Redesigned high frequency job scheduling system for higher resiliency and improved
          performance guarantees; oversaw implementation and go live.
        </li>
        <li>Created roadmap for php5 to php7 migration</li>
      </Experience>
      <Experience
        title="Product & Technology Consultant | experienceandamans.com"
        role={
          <div>
            Role: Drive website redesign to a modern look-and-feel; Advise team on its
            implementation using best practices.
          </div>
        }
      />
      <Experience
        title="Consulting Engineering Manager | Algorythma, algorythma.com"
        role={
          <div>
            Role: Kickstart a blockchain-based financial platform for businesses, banks and
            regulators to engage and transact, supported by PKI based identity KYCs; Build a world
            class team, create roadmaps and manage day-to-day affairs.
          </div>
        }
      >
        <li>Did extensive research on blockchain ecosystem to evaluate technology solution</li>
        <li>Actively contributed to product concept and UX design</li>
        <li>
          Conceptualized and executed "Stepped-MultiSig smart contract", which enables parties to
          create arbitrarily complex signatory requirements for passing board resolutions and
          executing transactions
        </li>
      </Experience>
    </div>
  );
}

function Experience(props) {
  const { title, about, role, children, timeRange } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between mb-2">
        <h3 className="text-xl">{title}</h3>
        {timeRange ? <div className="time-period">{timeRange}</div> : null}
      </div>
      {about ? <div>{about}</div> : null}
      {role ? <div>{role}</div> : null}
      <div>
        {/* <div className="text-sm italic mb-1">Highlights</div> */}
        <ul>{children}</ul>
      </div>
    </div>
  );
}

function HiverExp() {
  return (
    <Experience title="Engineering Head | Hiver, hiverhq.com" timeRange="Dec 2013 – Sep 2016">
      <li>
        Mentored team members to achieve their best potential, defined engineering vision for an
        enterprise class software
      </li>
      <li>Redesigned and executed the architecture to a highly sophisticated backend system</li>
      <li>
        Migrated software from monolithic system to SOA, massively improving scalability,
        performance and availability while also reducing infra costs
      </li>
      <li>Actively contributed to product roadmap and UX design</li>
      <li>Drove major intiatives aimed at improving product quality and reliability</li>
    </Experience>
  );
}

function PayuExp() {
  return (
    <Experience title="Team Lead | PayU India, payu.in" timeRange="Jun 2011 – May 2013">
      <li>Designed and developed Transaction Engine from scratch</li>
      <li>
        Led Core Engine, Refund & Cancellation, Reconciliation & Settlement, and PG Integration
        modules
      </li>
      <li>
        Led team in obtaining <b>PCI-DSS Level 1</b> certification
      </li>
      <li>
        Developed <b>Dynamic Switching Module</b> to enable auto-switching of gateway in the event
        of gateway downtimes
      </li>
    </Experience>
  );
}

function MobiculesExp() {
  return (
    <Experience title="Tech Lead | Mobilcules Technologies" timeRange="Jun 2008 – Jun 2011">
      <li>
        Owned project-wide technical implementation across multiple projects over the span of three
        years.
      </li>
      <li>Designed data models and developed backend services across multiple projects</li>
    </Experience>
  );
}

function LeftSection() {
  return (
    <div className="w-1/4 left-section px-8 py-12 flex flex-col gap-20">
      <div className="image-container">
        <img src={ProfileImg} />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-xl gap-2 mb-2">
          <FaRegAddressCard style={{ color: 'lightgray' }} />
          <h3>Personal Info</h3>
        </div>
        <div className="flex gap-2 items-center">
          <MdEmail style={{ color: 'lightgray' }} />
          mail.sushilc@gmail.com
        </div>
        <div className="flex gap-2 items-center">
          <AiTwotonePhone style={{ color: 'lightgray' }} />
          +91 9663922336
        </div>
        <div className="flex gap-2 items-center">
          <ImLocation2 style={{ color: 'lightgray' }} />
          Bangalore, India
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-xl gap-2 mb-2">
          <div>
            <GiGraduateCap style={{ color: 'lightgray' }} />
          </div>
          <h3>Education</h3>
        </div>
        <div>
          <div>Bachelor of Technology</div>
          <div>NIT Durgapur</div>
          <div>2008</div>
        </div>
      </div>
      <SkillsSummary />
    </div>
  );
}

function SkillsSummary() {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center text-xl gap-2 mb-2">
        <div>
          <GiStarsStack style={{ color: 'lightgray' }} />
        </div>
        <h3>Skills Summary</h3>
      </div>
      <Skill title="Engineering Management" level={4} />
      <Skill title="System Architecture" level={5} />
      <Skill title="Product Management" level={3} />
      <Skill title="UX Design" level={4} />
      <Skill title="UI Design" level={3} />
      <Skill title="Content & Marketing" level={2} />
    </div>
  );
}

function Skill(props) {
  const { title, level } = props;
  const fiveStars = [1, 2, 3, 4, 5];

  return (
    <div className="flex flex-col gap-1">
      <div>{title}</div>
      <div className="flex gap-2 -ml-1">
        {fiveStars.map((each) => {
          let color = 'text-white';
          if (level < each) {
            color = 'text-gray-700';
          }
          return <GoPrimitiveDot className={`text-lg  ${color}`} />;
        })}
      </div>
    </div>
  );
}
